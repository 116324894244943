
import { mapGetters } from 'vuex'
import filters from "@/mixins/filters";

export default {
	mixins: [filters],
	computed: {
		...mapGetters({
			 banks: 'banks/banks',
			settings: 'settings/settings/settings',
			allMarks: 'marks/marks/allMarks',
		}),
		 tbank(){
      if(this.banks && Array.isArray(this.banks)){
      return this.banks.filter(value => value.id === 3)[0]
      }else{
        return null
      }
	  }
	},

}
